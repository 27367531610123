<!-- OTP by google auth -->
<ng-template #otpGoogleAuthen
    let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pt-4">
    <div class="text-center mt-3 mb-3 custom-box">
      <div class="mb-2">
        {{"PROFILE.Enter the code generated by your authenticator application"|translate}}
      </div>
      <div class="mb-5 f-12 text-red">
        {{"PROFILE.OTP cannot be reused. Please wait 30 seconds for a new code"|translate}}
      </div>
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>

    <div class="form-group text-center mt-5">
      <button [disabled]="invalidOTP"
          (click)="otpChange.emit(otp)"
          [ladda]="isSaved"
          class="btn"
          type="button"
          appSetTheme>{{"APPROVAL.CONFIRM-APPROVE" | translate}}</button>
    </div>
  </div>
</ng-template>

<!-- OTP by email -->
<ng-template #otpEmail
    let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 text-uppercase font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"Email-OTP.EMAIL-OTP-SENT" | translate}} {{ hiddenEmail }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown"
          [class.text-danger]="timeLeft === 0">
        {{"SMS-OTP.Your code will expire in" | translate}}
        {{ timeLeft * 1000 | date:'mm:ss' }} {{"SMS-OTP.minutes" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>
    <div class="text-center mt-4">
      <div>{{"SMS-OTP.If you haven't received the code" | translate}}
        <span>
          <a class="text-blue border-bottom pointer"
              (click)="resend()">{{"SMS-OTP.resend the OTP" | translate}}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button"
        [style.--c]="themeList?.header_table_color"
        class="btn btn-outline btn-w-80"
        (click)="c('Close click')">{{"CREATE-USER.CANCEL"|translate}}
    </button>
    <button [disabled]="invalidOTP"
        (click)="otpChange.emit(otp)"
        [ladda]="isSaved"
        class="btn btn-w-80"
        type="button"
        appSetTheme>{{"APPROVAL.CONFIRM" | translate}}</button>
  </div>

</ng-template>

<!-- OTP by phone number -->
<ng-template #otpPhoneNumber
    let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"CREATE-USER.OTP-SENT" | translate}} {{ hiddenNumber }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown"
          [class.text-danger]="timeLeft === 0">
        {{"SMS-OTP.Your code will expire in" | translate}}
        {{ timeLeft * 1000 | date:'mm:ss' }} {{"SMS-OTP.minutes" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>
    <div class="text-center mt-4">
      <div>{{"SMS-OTP.If you haven't received the code" | translate}}
        <span>
          <a class="text-blue border-bottom pointer"
              (click)="resend()">{{"SMS-OTP.resend the OTP" | translate}}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button"
        [style.--c]="themeList?.header_table_color"
        class="btn btn-outline btn-w-80"
        (click)="c('Close click')">{{"CREATE-USER.CANCEL"|translate}}
    </button>
    <button [disabled]="invalidOTP"
        (click)="otpChange.emit(otp)"
        [ladda]="isSaved || isLoadingOTP"
        class="btn btn-w-80"
        type="button"
        appSetTheme>{{"APPROVAL.CONFIRM" | translate}}</button>
  </div>

</ng-template>
