<ng-template #downloadModal
    let-c="close">
  <app-modal-confirmation>
    <ng-template appCustomModalHeader>
      <div class="modal-header text-center">
        <h4 class="modal-title"
            *ngIf="!isExportGoogle && !evidenceDesign"
            id="modal-basic-title">
          {{ checkPage==='userPage' ?("EXPORT.USER-REPORT" | translate) :("EXPORT.MEMO-REPORT" | translate)}}
        </h4>
        <h4 class="modal-title"
            *ngIf="isExportGoogle"
            id="modal-basic-title">
          {{"TRASH.Export Memo"|translate}}
        </h4>
        <svg-icon src="assets/images/icons/btn-close.svg"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20 ,'margin-top.px':5}"
            class="pointer icon-close"
            (click)="close()"></svg-icon>
      </div>
    </ng-template>
    <ng-template appCustomModalBody>
      <div class="modal-body">
        <div class="col-12 p-0">
          <div class="full-width mx-2">
            <ngb-progressbar [type]="currentStateProgressType"
                [striped]="currentStateProgressType === 'info'"
                [animated]="currentStateProgressType === 'info'"
                [value]="sendingTaskDetail.percent"
                height="2.5rem">
              <div class="font-weight-bold">
                <span class="mb-0 mx-1"
                    *ngIf="sendingTaskDetail._state === celeryImportState.Progress">
                  {{ sendingTaskDetail.percent }}%
                </span>
                <h5 class="mb-0 mx-3 text-white"
                    *ngIf="sendingTaskDetail._state !== celeryImportState.Progress">
                  {{ 'EXPORT.' + sendingTaskDetail._state | translate }}</h5>
              </div>

            </ngb-progressbar>
          </div>

          <div class="full-width mx-2 mt-4"
              *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
            <ngb-alert type="danger"
                [dismissible]="false">
              <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
              <div class="frame error p-3"
                  [innerHTML]="sendingTaskDetail.detail"></div>
            </ngb-alert>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template appCustomModalFooter>
      <div class="modal-footer"
          *ngIf="sendingTaskDetail._state ==='SUCCESS' || celeryImportState.Progress">
        <button [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'"
            type="button"
            class="btn btn-outline btn-w-80"
            (click)="c('Close click')">
          {{ "MEMOS.CANCEL" | translate }}
        </button>
        <button appSetTheme
            type="button"
            class="btn btn-w-80"
            [disabled]="!filePath"
            (click)="downloadCSV()">
          {{"EXPORT.DOWNLOAD" | translate }}
        </button>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>

<ng-template #downloadEvidenceModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header text-center"
      style="background: #EAF1FB;">
    <h5 class="modal-title"
        id="modal-basic-title">
      {{'EXPORT-FILE.Attaching evidence' | translate}}
    </h5>
  </div>
  <div class="row justify-content-center">
    <div class="text-center col-10 mt-4">
      <div class="full-width mx-2">
        <ngb-progressbar [type]="currentStateProgressType"
            [striped]="currentStateProgressType === 'info'"
            [animated]="currentStateProgressType === 'info'"
            [value]="sendingTaskDetail.percent"
            height="2rem">
        </ngb-progressbar>
      </div>

      <div class="full-width mx-2 mt-4"
          *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
        <ngb-alert type="danger"
            [dismissible]="false">
          <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
          <div class="frame error p-3"
              [innerHTML]="sendingTaskDetail.detail"></div>
        </ngb-alert>
      </div>
    </div>
    <div class="p-5"
        *ngIf="evidenceDesign">
      {{'EXPORT-FILE.TEXT-WAIT-PDF' | translate}}
    </div>

    <div class="full-width mx-2 mt-4"
        *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
      <ngb-alert type="danger"
          [dismissible]="false">
        <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
        <div class="frame error p-3"
            [innerHTML]="sendingTaskDetail.detail"></div>
      </ngb-alert>
    </div>
  </div>
</ng-template>

<ng-template #evidenceSuccessModal
    let-c='close'>
  <div class="modal-header align-items-center"
      style="background: #EAF1FB;">
    <h3 class="m-b-0">
      {{"EXPORT-FILE.Evidence successfully attached" | translate}}
    </h3>
  </div>
  <div class="modal-body p-b-40 d-flex flex-column justify-contents-center align-items-center">
    <img src="assets/images/icons/evidence-success.svg"
        width="100"
        height="100"
        class="my-2"
        alt="Successfully Sent Document Image">
    <h5 class="my-2">
      {{"EXPORT-FILE.The evidence was successfully attached to PDF" | translate}}
    </h5>
    <div class="text-center">
      <p class="my-2">
        {{"EXPORT-FILE.TEXT-CONTENT-1" | translate}}
      </p>
      <p>
        {{"EXPORT-FILE.TEXT-CONTENT-2" | translate}}
      </p>
    </div>
    <button class="btn btn-primary mt-3"
        (click)="goBack()">
      {{"EXPORT-FILE.GO-TO-CONTRACT" | translate}}
    </button>
  </div>
</ng-template>